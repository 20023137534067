import React from 'react';

const HowItWorksSection = ({id}) => {
    const steps = [
        {
            title: "Pulling Smart Contracts",
            description: "Our AI scans multiple blockchains to gather smart contract data.",
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="w-16 h-16 mb-4">
                    <rect x="2" y="2" width="20" height="8" rx="2" ry="2"></rect>
                    <rect x="2" y="14" width="20" height="8" rx="2" ry="2"></rect>
                    <line x1="6" y1="6" x2="6" y2="6"></line>
                    <line x1="6" y1="18" x2="6" y2="18"></line>
                </svg>
            )
        },
        {
            title: "Analysis",
            description: "Advanced algorithms analyze the code for innovative patterns and features.",
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="w-16 h-16 mb-4">
                    <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
                </svg>
            )
        },
        {
            title: "Ranking",
            description: "Projects are ranked based on their innovation score and potential impact.",
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="w-16 h-16 mb-4">
                    <path d="M18 20V10"></path>
                    <path d="M12 20V4"></path>
                    <path d="M6 20V14"></path>
                </svg>
            )
        }
    ];

    return (
        <section className="container mx-auto px-4 py-10" id={id}>
            <h2 className="text-4xl font-bold mb-12 text-center">How It Works</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {steps.map((step, index) => (
                    <div key={index} className="rounded-lg p-6 text-center">
                        <div className="flex justify-center text-purple-500">{step.icon}</div>
                        <h3 className="text-xl font-bold mb-2">{step.title}</h3>
                        <p className="text-gray-400">{step.description}</p>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default HowItWorksSection;