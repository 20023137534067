import React from 'react';
import { Twitter, MessageCircle, BarChart2, Lock } from 'lucide-react';

const TopProjectsSection = ({id}) => {
    const topProjects = [
        {
            name: "DeFi Protocol Alpha",
            chain: "Ethereum",
            innovation: 95,
            description: "Revolutionary DeFi protocol with advanced yield optimization.",
            logo: "/api/placeholder/48/48", // Replace with actual logo URL
            twitter: "https://twitter.com/defiprotocolalpha",
            telegram: "https://t.me/defiprotocolalpha",
            dexScreener: "https://dexscreener.com/ethereum/defiprotocolalpha",
            isPremium: false,
        },
        {
            name: "NFT Marketplace Beta",
            chain: "Polygon",
            innovation: 88,
            description: "Next-gen NFT marketplace with AI-powered curation.",
            logo: "/api/placeholder/48/48", // Replace with actual logo URL
            twitter: "https://twitter.com/nftmarketbeta",
            telegram: "https://t.me/nftmarketbeta",
            dexScreener: "https://dexscreener.com/polygon/nftmarketbeta",
            isPremium: true,
        },
        {
            name: "GameFi Platform Gamma",
            chain: "Solana",
            innovation: 82,
            description: "Immersive GameFi ecosystem blending AAA gaming with DeFi.",
            logo: "/api/placeholder/48/48", // Replace with actual logo URL
            twitter: "https://twitter.com/gamefiplatformgamma",
            telegram: "https://t.me/gamefiplatformgamma",
            dexScreener: "https://dexscreener.com/solana/gamefiplatformgamma",
            isPremium: false,
        },
        {
            name: "DAO Framework Delta",
            chain: "Ethereum",
            innovation: 79,
            description: "Modular DAO framework for customizable governance.",
            logo: "/api/placeholder/48/48", // Replace with actual logo URL
            twitter: "https://twitter.com/daoframeworkdelta",
            telegram: "https://t.me/daoframeworkdelta",
            dexScreener: "https://dexscreener.com/ethereum/daoframeworkdelta",
            isPremium: false,
        },
        {
            name: "Lending Protocol Epsilon",
            chain: "Polygon",
            innovation: 75,
            description: "Decentralized lending protocol with cross-chain support.",
            logo: "/api/placeholder/48/48", // Replace with actual logo URL
            twitter: "https://twitter.com/lendingprotocolepsilon",
            telegram: "https://t.me/lendingprotocolepsilon",
            dexScreener: "https://dexscreener.com/polygon/lendingprotocolepsilon",
            isPremium: false,
        }
    ];

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const QuestionMarkSVG = () => (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="w-8 h-8 text-gray-500">
            <circle cx="12" cy="12" r="10" />
            <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
            <line x1="12" y1="17" x2="12.01" y2="17" />
        </svg>
    );

    const ProjectCard = ({ project }) => (
        <div className="bg-gray-800 rounded-lg p-4 shadow-lg flex flex-col h-full">
            <div className="flex items-start mb-2">
                {project.isPremium ? (
                    <div className="w-8 h-8 mr-2 rounded-full bg-gray-700 flex items-center justify-center flex-shrink-0">
                        <QuestionMarkSVG />
                    </div>
                ) : (
                    project.logo && <img src={project.logo} alt={`${project.name} logo`} className="w-8 h-8 mr-2 rounded-full flex-shrink-0" />
                )}
                <div>
                    <h3 className="text-sm font-semibold">
                        {project.isPremium ? "Premium Project" : project.name}
                    </h3>
                    <p className="text-xs text-gray-400">{project.chain}</p>
                </div>
            </div>
            <p className="text-xs text-gray-300 mb-2 flex-grow line-clamp-2">{project.description}</p>
            <div className="mt-auto">
                <div className="mb-2">
                    <div className="bg-purple-900 rounded-full h-1.5">
                        <div
                            className="bg-purple-500 h-1.5 rounded-full"
                            style={{width: `${project.innovation}%`}}
                        ></div>
                    </div>
                    <p className="text-xs text-gray-400 mt-1">Innovation: {project.innovation}</p>
                </div>
                <p className="text-xs text-gray-400 mb-2">Deployed: {formatDate(project.deploymentDate)}</p>
                {!project.isPremium ? (
                    <div className="flex justify-between">
                        <a href={project.twitter} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
                            <Twitter size={16} />
                        </a>
                        <a href={project.telegram} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
                            <MessageCircle size={16} />
                        </a>
                        <a href={project.dexScreener} target="_blank" rel="noopener noreferrer" className="text-green-400 hover:text-green-300">
                            <BarChart2 size={16} />
                        </a>
                    </div>
                ) : (
                    <button className="w-full bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center">
                        <Lock size={16} className="mr-2" />
                        Unlock Premium
                    </button>
                )}
            </div>
        </div>
    );

    return (
        <section className="container mx-auto px-4 py-10" id={id}>
            <h2 className="text-3xl font-bold mb-8 text-center">Top Projects This Week</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                {topProjects.map((project, index) => (
                    <ProjectCard key={index} project={project}/>
                ))}
            </div>
            <div className="text-center py-2">
                <button className="bg-white text-purple-900 font-bold py-3 px-8 rounded-lg text-lg">
                    Go to App!
                </button>
            </div>
        </section>
    );
};

export default TopProjectsSection;