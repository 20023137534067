import React from 'react';
import { Check } from 'lucide-react';

const PricingCard = ({ title, price, currency, features, buttonText, isPremium }) => (
    <div className={`bg-gray-800 rounded-lg p-6 flex flex-col h-full ${isPremium ? 'border-2 border-purple-500' : ''}`}>
        <h3 className="text-xl font-bold mb-4">{title}</h3>
        <div className="mb-6">
            <span className="text-3xl font-bold">{price}</span>
            <span className="text-gray-400 ml-2">{currency}</span>
        </div>
        <ul className="mb-6 flex-grow">
            {features.map((feature, index) => (
                <li key={index} className="flex items-center mb-2">
                    <Check className="text-green-500 mr-2" size={16} />
                    <span className="text-sm">{feature}</span>
                </li>
            ))}
        </ul>
        <button className={`w-full py-2 px-4 rounded font-bold ${
            isPremium ? 'bg-purple-600 hover:bg-purple-700' : 'bg-gray-700 hover:bg-gray-600'
        }`}>
            {buttonText}
        </button>
    </div>
);

const PricingSection = ({id}) => {
    const pricingPlans = [
        {
            title: "Free",
            price: "0",
            currency: "",
            features: [
                "Access to basic project rankings",
                "Limited project details",
                "Weekly newsletter"
            ],
            buttonText: "Get Started",
            isPremium: false
        },
        {
            title: "Premium $GEMGUNNER",
            price: "0.15",
            currency: "SOL worth of $GEMGUNNER / month",
            features: [
                "All Premium SOL features",
                "Exclusive $GEMGUNNER holder events",
                "Voting rights on platform decisions",
                "Discounted partner services"
            ],
            buttonText: "Subscribe with $GEMGUNNER",
            isPremium: true
        },
        {
            title: "Premium SOL",
            price: "0.25",
            currency: "SOL / month",
            features: [
                "Full access to all project rankings",
                "Detailed project analysis",
                "Early access to new features",
                "Premium support"
            ],
            buttonText: "Subscribe with SOL",
            isPremium: true
        },
    ];

    return (
        <section className="container mx-auto px-4 py-10" id={id}>
            <h2 className="text-4xl font-bold mb-12 text-center">Choose Your Plan</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {pricingPlans.map((plan, index) => (
                    <PricingCard key={index} {...plan} />
                ))}
            </div>
        </section>
    );
};

export default PricingSection;