import React from 'react';
import Home from "./components/Home";
import { HelmetProvider } from 'react-helmet-async';

const App = () => {
  return (
      <HelmetProvider>
        <Home />
      </HelmetProvider>
  )
}


export default App;